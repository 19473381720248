






















import Loading from '@/entidades/Sistema/Loading';
import {Vue,Component, Prop} from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import {ChangePasswordModel,ChangePassword} from '@/entidades/Maestro/Sistema/UsuarioInfo';
import Alerta from '@/entidades/Sistema/Alerta';

@Component({
    name : 'ChangePassword'
})
export default class ChangePasswordComponent extends Vue
{

    //#region Props
    @Prop({type:String ,required:true}) usuarioId!:string
    //#endregion
    //#region Propiedad simple
     //variables
    password:string="";
    confPassword:string="";
    //#endregion
    //#region Vuex
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
    
    //#endregion

    //#region Computed
    get validarPassword()
    {
        if(this.password == this.confPassword && this.password != "" && this.confPassword != "")
        {
            return true;
        }
        else
        {
            return false;
        }
    }
    //#endregion
    //#region Metodos
    async Guardar()
    {
        try
        {
            this.changeLoading(new Loading(true,"Actualizando Contraseña"))
            var modelPassword = new ChangePasswordModel(this.usuarioId,this.password);
            var rsp = await ChangePassword(modelPassword);
            if(rsp.isSuccess)
            {
                this.changeAlerta(new Alerta("Password modificado exitosamente",true,"primary",3000));
                this.$emit('close',false);
            }
            else
            {
                this.changeAlerta(new Alerta(rsp.isMessage,true,"primary",3000));
                this.$emit('close',false);
            }

        }
        catch(error)
        {

        }
        finally
        {
            this.changeLoading(new Loading(false,""))
        }
    }
    //#endregion

}
