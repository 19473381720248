import {Save,ObtenerObject,Editar} from '../../../Generico/Conexion';
import { mainAxios } from '@/plugins/axios';
export class InfoUsuario 
{
    usuarioId!:string;
    firstName!: string;
    lastName!: string;
    email!: string;
    sistema!: string;
    rol!: string;
    perfil!: string;
    planta!: string;
    userName!:string;
}

export class ChangePasswordModel
{
    usuarioId: string;
    password: string;
    constructor(_usuarioId:string,_password:string)
    {
        this.usuarioId = _usuarioId;
        this.password = _password;
    }
}

export async function ObtenerInfoUsuario(usuarioId:string)
{
    return ObtenerObject<InfoUsuario>(`usuario/getinfousuario/${usuarioId}`,mainAxios);
}

export async function ProfileUpdate(info:InfoUsuario)
{
    return Editar<InfoUsuario>('usuario/profileupdate',info, mainAxios);
}
export async function ChangePassword(info:ChangePasswordModel)
{
    return Editar<ChangePasswordModel>('usuario/changePassword',info, mainAxios);
}